import { z } from 'zod'

export const zUpdateUserMarketingDataTrpcInput = z.object({
  userId: z.string(),
  gclid: z.string(),
  fbclid: z.string(),
  customclid: z.string(),
  anyclid: z.string(),
  mediaSource: z.string(),
  channel: z.string(),
  campaign: z.string(),
  campaignId: z.string(),
  adset: z.string(),
  adsetId: z.string(),
  ad: z.string(),
  adId: z.string(),
  siteId: z.string(),
  keywords: z.string(),
})
